import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import {
  LOAD_MEDICATIONS
} from '../../../store/actions';
import { supabase } from '../../../lib/supabase';
import { notifyError, notifySuccess } from '../../../utils/notify';
import ReactDatetime from "react-datetime";
import { HeaderWithTutorial } from '../../shared/HeaderWithTutorial'
import { RxMgmtModalTutorialAdvanced } from './RxMgmtModalTutorialAdvanced'
import { useQuery } from '@tanstack/react-query'
import { SubscriptionUpgradeRequired } from '../../subscriptions/SubscriptionUpgradeRequired'
import { isSubscriptionTierAvailable } from '../../../utils/subscriptionTierAvailablityCheck';
import { getActiveSubscriptions } from '../../../supabase-api/active_subscriptions';
import { getCurrentUser } from '../../../supabase-api/current_user';
import { MedicationReminderCheckbox } from '../shared/MedicationReminderCheckbox'

const initialFormState = {
  type: 'PRESCRIPTION',
  name: '',
  dose: '',
  frequency: ''
}

const initialSelectedState = {
  id: '',
  type: 'PRESCRIPTION',
  name: '',
  dose: '',
  frequency: '',
  profileMedicationsId: ''
}


function MedicationManagementAdvanced({ profile, medical, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [singleSelections, setSingleSelections] = useState([]);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [singleSelectedSelections, setSelectedSingleSelections] = useState([]);

  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {

    // Set format data
    setFormData({
      ...formData,
      'profileMedicationsId': profile.user.id
    });

    getPrescriptions(profile.user.id);
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;

    // Check if valid - first + last name
    if (formData.name != "") {
      // Set save flag
      setAddItem(true);
    } else {
      // Set save flag
      setAddItem(false);
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [formData]);

  async function getPrescriptions(profileId) {
    const { data, error } = await supabase
      .from('medication')
      .select(`*, medication_type!inner(*)`)
      .eq('medication_type.name', 'PRESCRIPTION')
      .eq('profile_id', profileId)

    if (error) {
      console.error("Error fetching prescriptions: ", error);
    }

    dispatch({
      type: LOAD_MEDICATIONS,
      payload: {
        type: "PRESCRIPTION",
        items: data
      }
    });
  }

  async function savePrescription() {
    if (!formData.name) return;

    const { data: medicationTypeData, error: medicationTypeError } = await supabase
      .from('medication_type')
      .select('id')
      .eq('name', 'PRESCRIPTION')
      .single()

    if (medicationTypeError) {
      console.error('Error fetching medication type id:', error);
      return null
    }

    const { data, error } = await supabase
      .from('medication')
      .insert({
        name: formData.name,
        dose: formData.dose,
        frequency: formData.frequency,
        medication_type_id: medicationTypeData.id,
        date_started: formData.date_started,
        notes: formData.notes,
      })

    if (error) {
      console.error("Error creating medication: ", error)
      notifyError("Error adding medication")
      return
    }

    notifySuccess("Medication added successfully")

    // Clear form data
    setFormData({
      ...initialFormState,
      'profileMedicationsId': profile.user.id
    });

    // Clear selection
    setSingleSelections([]);

    // Reset flag
    setAddItem(false);

    await getPrescriptions(profile.user.id);
  }

  async function removePrescription(medicationId) {
    const { error } = await supabase
      .from('medication')
      .delete()
      .eq('id', medicationId)

    if (error) {
      console.error("Error deleting medication: ", error)
      notifyError("Error deleting medication")
      return
    }

    notifySuccess("Medication deleted successfully")

    await getPrescriptions(profile.user.id);
  }

  async function modifyPrescription(medication) {
    const { data, error } = await supabase
      .from('medication')
      .update({
        name: medication.name,
        dose: medication.dose,
        frequency: medication.frequency,
        date_started: medication.date_started,
        notes: medication.notes,
      })
      .eq('id', medication.id)

    if (error) {
      console.error("Error updating medication: ", error)
      notifyError("Error updating medication")
      return
    }

    notifySuccess("Medication updated successfully")

    await getPrescriptions(profile.user.id);
  }

  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery({
    queryKey: ['current_user'],
    queryFn: () => getCurrentUser(),
  })

  const { data: activeSubscriptions, isLoading: isLoadingActiveSubscriptions } = useQuery({
    queryKey: ['user_subscriptions'],
    queryFn: () => getActiveSubscriptions(currentUser.id),
    retry: false,
    enabled: !!currentUser
  })

  if (isLoadingActiveSubscriptions || !activeSubscriptions || isCurrentUserLoading || isLoadingActiveSubscriptions) {
    return 'Loading...'
  }

  // LEAVE THIS UNCOMMENTED!
  const isTierAvailable = isSubscriptionTierAvailable(activeSubscriptions.map(sub => sub.products.name), 'Medication Management Advanced')
  if (!isTierAvailable) {
    return <SubscriptionUpgradeRequired
      serviceName="Medication Management"
      subscriptionTier="Advanced"
      subscriptionUrl={process.env.REACT_APP_SUBSCRIPTION_SITE_URL || '#'}
    />
  }

  return (
    <>
      <HeaderWithTutorial
        serviceName="Medication Management"
        subscriptionTier="Advanced"
        tutorialContent={<RxMgmtModalTutorialAdvanced />}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Prescription Medication</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-name"
                          >
                            Medication Name (Required)
                          </label>
                          <Input
                            id="input-prescription-name"
                            placeholder="Prescription name"
                            type="text"
                            value={formData.name}
                            autoComplete="off"
                            onChange={e => setFormData({ ...formData, 'name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-dose"
                          >
                            Dose
                          </label>
                          <Input
                            id="input-prescription-dose"
                            placeholder="Prescription dose"
                            type="text"
                            value={formData.dose}
                            autoComplete="off"
                            onChange={e => setFormData({ ...formData, 'dose': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-frequency"
                          >
                            Frequency
                          </label>
                          <Input
                            id="input-prescription-frequency"
                            placeholder="Prescription frequency"
                            type="text"
                            value={formData.frequency}
                            autoComplete="off"
                            onChange={e => setFormData({ ...formData, 'frequency': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-date"
                          >
                            Date Prescribed
                          </label>
                          <ReactDatetime
                            id="input-prescription-date"
                            inputProps={{
                              placeholder: "01/01/2022",
                            }}
                            timeFormat={false}
                            onChange={date => {
                              // Check if string (manual entry)
                              if (typeof date == "string") {
                                // Let module parse
                              } else { // Moment Class
                                // Set date
                                setFormData({ ...formData, 'date_started': date.format('YYYY-MM-DD') });
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-notes"
                          >
                            Notes
                          </label>
                          <Input
                            id="input-notes"
                            rows="5"
                            placeholder="Prescription notes"
                            type="textarea"
                            value={formData.notes}
                            onChange={e => setFormData({ ...formData, 'notes': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6"></Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={savePrescription}
                          size="xl"
                          disabled={!addItem}
                        >
                          Add Prescription
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Manage My Prescription Medications</h3>
                  </Col>
                  <Col xs="6">
                    <MedicationReminderCheckbox />
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Dose</th>
                    <th>Frequency</th>
                    <th>Date Prescribed</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {medical.medications.prescription.map((medication, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <Link
                          className="font-weight-bold"
                          to={`/medication-management/medication-advanced/${medication.id}`}
                        >
                          {medication.name}
                        </Link>
                      </td>
                      <td>
                        <span className="text-muted">
                          {medication.dose}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {medication.frequency}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {/* {format(new Date(medication.date_started).toLocaleDateString())} */}
                          {/* {medication.date_started} */}
                          {medication.date_started && new Intl.DateTimeFormat('en-US').format(new Date(medication.date_started))}
                        </span>
                      </td>
                      <td>
                        <div className="text-muted"
                          style={{
                            width: 300,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'inline-block'
                          }}
                        >
                          {medication.notes}
                        </div>
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action table-action-delete"
                          href="#"
                          id="tooltip601065234"
                          onClick={() => removePrescription(medication.id)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip601065234">
                          Delete Medication
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}

      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Prescription
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Name
                    </label>
                    <Input
                      id="input-condition"
                      placeholder=""
                      type="text"
                      value={selectedData.name}
                      onChange={e => setSelectedData({ ...selectedData, 'name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-dose"
                    >
                      Dose
                    </label>
                    <Input
                      id="input-prescription-dose"
                      placeholder=""
                      type="text"
                      value={selectedData.dose}
                      onChange={e => setSelectedData({ ...selectedData, 'dose': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-frequency"
                    >
                      Frequency
                    </label>
                    <Input
                      id="input-prescription-frequency"
                      placeholder=""
                      type="text"
                      value={selectedData.frequency}
                      onChange={e => setSelectedData({ ...selectedData, 'frequency': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" xs="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-date"
                    >
                      Date Prescribed
                    </label>
                    <ReactDatetime
                      id="input-prescription-date"
                      inputProps={{
                        placeholder: selectedData.date_started,
                      }}
                      timeFormat={false}
                      onChange={date => {
                        // Check if string (manual entry)
                        if (typeof date == "string") {
                          // Let module parse
                        } else { // Moment Class
                          // Set date
                          setSelectedData({ ...selectedData, 'date_started': date.format('YYYY-MM-DD') });
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-notes"
                    >
                      Notes
                    </label>
                    <Input
                      id="input-notes"
                      rows="5"
                      placeholder=""
                      type="textarea"
                      value={selectedData.notes}
                      onChange={e => setSelectedData({ ...selectedData, 'notes': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href="#"
            onClick={() => {
              modifyPrescription(selectedData);

              // Close modal
              setdefaultModal(false);
            }}
            size="xl"
          >
            Update Prescription
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedMedicationManagementAdvanced = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(MedicationManagementAdvanced);

export default ConnectedMedicationManagementAdvanced;
